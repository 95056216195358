@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap);
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Poppins', sans-serif;
  background: #1f1f38;
  background: var(--color-bg);
  color: #fff;
  color: var(--color-white);
  line-height: 1.7;
}

/* GENERAL STYLES */

.container {
  width: 75%;
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5em;
}

section {
  margin-top: 8rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  color: var(--color-light);
}

section > h2 {
  color: #4db5ff;
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: rgba(255, 255, 255, 0.6);
  color: var(--color-light);
}

a {
  color: #4db5ff;
  color: var(--color-primary);
  transition: all 400ms ease;
  transition: var(--transition);
}

a:hover {
  color: #fff;
  color: var(--color-white);
}

.btn {
  width: -webkit-max-content;
  width: max-content;
  display: inline-block;
  color: #4db5ff;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid #4db5ff;
  border: 1px solid var(--color-primary);
  transition: all 400ms ease;
  transition: var(--transition);
}
.btn:hover {
  background: #fff;
  background: var(--color-white);
  color: #1f1f38;
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: #4db5ff;
  background: var(--color-primary);
  color: #1f1f38;
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* ---------MEDIA QUERIES (MEDIUM DEVICES) ---------*/
@media screen and (max-width: 1024px) {
  .container {
    width: 86%;
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/* ---------MEDIA QUERIES (SMALL  DEVICES) ---------*/
@media screen and (max-width: 600) {
  .container {
    width: 90%;
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-top: 2rem;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.container.contact__container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  grid-gap: 12%;
  gap: 12%;
}

.contact__options {
  display: flex;
  flex-direction: column;
  grid-gap: 1.2rem;
  gap: 1.2rem;
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact_option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}
.contact__option h5 {
  color: var(--color-light);
}

/*=== FORM===*/

form {
  display: flex;
  flex-direction: column;
  grid-gap: 1.2rem;
  gap: 1.2rem;
}
input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}

/* ---------MEDIA QUERIES (MEDIUM DEVICES) ---------*/
@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
    gap: 2rem;
  }
}

/* ---------MEDIA QUERIES (SMALL  DEVICES) ---------*/
@media screen and (max-width: 600px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    width: var(--container-width-sm);
  }
}

.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  gap: 2rem;
}

.experience__container > div {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.experience__details {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.experience__details-icons {
  margin-top: 0.4em;
  color: var(--color-primary);
}

/* ---------MEDIA QUERIES (MEDIUM DEVICES) ---------*/
@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }

  .experience__container > div {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .experience__content {
    padding: 1rem;
  }
}

/* ---------MEDIA QUERIES (SMALL  DEVICES) ---------*/
@media screen and (max-width: 600px) {
  .experience__container {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .experience__container > div {
    width: 100%;
    padding: 2rem 1rem;
  }
}

header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.cta {
  margin-top: 2.5rem;
  display: flex;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  justify-content: center;
}

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

.me {
  background-color: (--color-primary);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 9.5rem);
  margin-top: 3rem;
  border-radius: 12rem 12rem 0 0;
  padding: 0rem 1.5rem 1.5rem 1.5rem;
}

.scroll__down {
  position: absolute;
  right: 2.3rem;
  bottom: 5rem;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  header {
    height: 100vh;
  }
}

/* ---------MEDIA QUERIES (SMALL  DEVICES) ---------*/
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}

nav {
  background: rgba(0, 0, 0, 0.3);
  width: -webkit-max-content;
  width: max-content;
  display: block;
  padding: 0.7rem 1.7rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  grid-gap: 0.8rem;
  gap: 0.8rem;
  border-radius: 3rem;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
}

nav a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  font-size: 1.1rem;
}

nav a:hover {
  background: rgba(0, 0, 0, 0.3);
}

nav a.active {
  background: var(--color-primary);
  color: var(--color-bg);
}

.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  grid-gap: 15%;
  gap: 15%;
}

.about__me {
  width: 100%;
  aspect-ratio: 4/1;
  /*aspect ratio 1/1*/
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary),
    transparent
  );
  place-items: center;
}

.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  -webkit-transform: rotate(10deg);
          transform: rotate(10deg);
  transition: var(--transition);
}

.about__me-image:hover {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
}

.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
}

.about__cards:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 0.95rem;
}

.about__card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

/* ---------MEDIA QUERIES (MEDIUM DEVICES) ---------*/
@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    grid-gap: 0;
    gap: 0;
  }

  .about__me {
    width: 20%;
    margin: 2rem auto 1.5rem;
  }
}

/* ---------MEDIA QUERIES (SMALL  DEVICES) ---------*/
@media screen and (max-width: 600px) {
  .about__me {
    width: 65%;
    margin: 0 auto 3rem;
  }
  .about__cards {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .about__content {
    text-align: center;
  }
  .about__content p {
    margin: 1.5rem 0;
  }
}

.services__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  gap: 3rem;
}

.service {
  background: var(--color-bg-variant);
  border-radius: 0 0 2rem 2rem;
  border: 1px solid var(--color-primary);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  transition: var(--transition);
}

.service:hover {
  background: transparent;
  border-color: var(--color-bg-variant);
  cursor: default;
}

.service__head {
  background: var(--color-primary);
  padding: 2rem;
  border-radius: 0 0 2rem 2rem;
  box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}

.service__head h3 {
  color: var(--color-bg);
  font-size: 1rem;
  text-align: center;
}
.service__list {
  padding: 2rem;
}

.service__list li {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.service__list-icon {
  color: var(--color-primary);
  margin-top: 2px;
}

.service__list p {
  font-size: 0.9rem;
}

/* ---------MEDIA QUERIES (MEDIUM DEVICES) ---------*/
@media screen and (max-width: 1024px) {
  .services__container {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    gap: 2rem;
  }
  .service {
    height: auto;
  }
}

/* ---------MEDIA QUERIES (SMALL  DEVICES) ---------*/
@media screen and (max-width: 600px) {
  .services__container {
    grid-template-columns: 1fr;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5rem;
  gap: 2.5rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;

  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}
.portfolio__item-image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1rem;
}

/* ---------MEDIA QUERIES (MEDIUM DEVICES) ---------*/
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.2rem;
    gap: 1.2rem;
  }
}

/* ---------MEDIA QUERIES (SMALL  DEVICES) ---------*/
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    gap: 1rem;
  }
}

footer {
  background: var(--color-primary);
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
}

footer a {
  color: var(--color-bg);
}
.footer__logo {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  margin: 0 auto 3rem;
}

.footer__copyright {
  margin-bottom: 4rem;
  color: var(--color-bg);
}

/* ---------MEDIA QUERIES (SMALL  DEVICES) ---------*/
@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

