header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header__socials::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

.me {
  background-color: (--color-primary);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 9.5rem);
  margin-top: 3rem;
  border-radius: 12rem 12rem 0 0;
  padding: 0rem 1.5rem 1.5rem 1.5rem;
}

.scroll__down {
  position: absolute;
  right: 2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  header {
    height: 100vh;
  }
}

/* ---------MEDIA QUERIES (SMALL  DEVICES) ---------*/
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  .header__socials,
  .scroll__down {
    display: none;
  }
}
